import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../api/axios';
import { setAlert } from './globalAppSlice';

export const fetchZohoAccounts = async (searchValue) => {
  try {
    const response = await axiosInstance.post(`/api/searchZohoAccounts`, {
      searchValue: searchValue,
    });

    if (response?.data?.data && response?.data?.success) {
      return response?.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
  }
};


export const fetchZohoLeads = async (searchValue) => {
  try {
    const response = await axiosInstance.post(`/api/searchZohoLeads`, {
      searchValue: searchValue,
    });

    if (response?.data?.data && response?.data?.success) {
      return response?.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
  }
};

// New: Search Zoho Leads (Async Thunk)
export const searchZohoLeads = createAsyncThunk(
  '/api/searchZohoLeads',
  async (searchValue, { dispatch }) => {
    try {
      const response = await axiosInstance.post(`/api/searchZohoLeads`, {
        searchValue: searchValue,
      });

      if (response?.data?.data && response?.data?.success) {
        return response?.data;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
    }
  }
);


export const removeZohoAccountFromLayer = createAsyncThunk(
  'zoho/removeZohoAccount',
  async (accountId, { rejectWithValue,dispatch }) => {
    try {
      const response = await axiosInstance.delete(`/api/removeZohoAccount/${accountId}`);
      console.log(response);
      
      if (response.data?.success) {
        dispatch(
          setAlert({
            message: response.data?.msg,
            type: 'success',
            open: true,
          })
        );
        return accountId;
         // Return the account ID so the state can be updated
      } else {
        return rejectWithValue(response.data?.message || 'Failed to remove account from layer');
      }
    } catch (error) {
      console.error('Error in API call:', error);
      return rejectWithValue(error.response?.data || 'Error removing Zoho account from layer');
    }
  }
);

export const searchZohoAccounts = createAsyncThunk(
  '/api/searchZohoAccounts',
  async (searchValue, { dispatch }) => {
    try {
      const response = await axiosInstance.post(`/api/searchZohoAccounts`, {
        searchValue: searchValue,
      });

      if (response?.data?.data && response?.data?.success) {
        return response?.data;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
    }
  }
);

export const zohoSlice = createSlice({
  name: 'zoho',
  initialState: {
    zohoAccounts: [],
    zohoLeads: [], 
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(searchZohoAccounts.fulfilled, (state, action) => {
      if (action.payload?.data) {
        let zohoAccounts = [];
        const zohoAccountsData = action.payload?.data;
        zohoAccountsData?.forEach((zohoAccount) => {
          const accountData = {
            id: zohoAccount?.id,
            label: zohoAccount?.Account_Name,
            email: zohoAccount?.Email,
            Location_Map_Layer: zohoAccount?.Location_Map_Layer,
          };
          zohoAccounts.push(accountData);
        });
        state.zohoAccounts = zohoAccounts;
      }
      state.loading = false; // Ensure to reset loading state
    })
    .addCase(searchZohoAccounts.pending, (state) => {
      state.loading = true; // Add pending state for search
    })
    .addCase(searchZohoAccounts.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error?.message || "Failed to search Zoho accounts";
    })


    .addCase(searchZohoLeads.fulfilled, (state, action) => {
      if (action.payload?.data) {
        let zohoLeads = [];
        const zohoLeadsData = action.payload?.data;
        zohoLeadsData?.forEach((zohoLead) => {
          const leadData = {
            id: zohoLead?.id,
            label: zohoLead?.Full_Name,  
            email: zohoLead?.Email,
            Location_Map_Layer: zohoLead?.Location_Map_Layer,
          };
          zohoLeads.push(leadData);
        });
        state.zohoLeads = zohoLeads;
      }
      state.loading = false; // Ensure to reset loading state
    })
    .addCase(searchZohoLeads.pending, (state) => {
      state.loading = true; // Add pending state for search
    })
    .addCase(searchZohoLeads.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error?.message || "Failed to search Zoho leads";
    })


    .addCase(removeZohoAccountFromLayer.fulfilled, (state, action) => {
      const accountId = action.payload;
      state.zohoAccounts = state.zohoAccounts.map((account) =>
        account.id === accountId ? { ...account, Location_Map_Layer: null } : account
      );
      state.loading = false;
    })
    .addCase(removeZohoAccountFromLayer.pending, (state) => {
      state.loading = true;
    })
    .addCase(removeZohoAccountFromLayer.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload; // Capture the error message
    });
},
});

export default zohoSlice.reducer;
